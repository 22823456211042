/* global System */
import {
  registerApplication,
  start,
  addErrorHandler,
  getAppStatus,
  unloadApplication,
} from "single-spa";
import createContextObject from "../communication/create-context-object";
import getContext from "../communication/get-context";
import mfeRetry from "./mfe-retry";
import {
  logApplicationEvent,
  logApplicationError,
} from "../logger/ceme-logger";
import getActiveMFE from "./get-active-mfe";
import getMfeStatus from "./get-mfe-status";
import getCorrelationId from "./get-correlation-id";
import getMfeRoutes from "../communication/get-mfe-routes";

export default function registerStartApp() {
  const activeMFEs = getActiveMFE();
  activeMFEs.forEach((currentMfe) => {
    try {
      registerApplication(
        currentMfe.name,
        () =>
          System.import(currentMfe.loader)
            .then((module) => module.load())
            .catch((err) => {
              logApplicationError({
                type: getAppStatus(currentMfe.name),
                name: currentMfe.name,
                object: err,
                description: `Error in loading function during registerApplication call`,
                code: "MFE-ERR03",
              });
            }),
        (location) => {
          let shouldMount = false;
          currentMfe.mountingRoutes.forEach((route) => {
            if (location.pathname.startsWith(route)) {
              shouldMount = true;
            }
          });
          return shouldMount;
        },
        {
          getContext,
          createContextObject,
          getCorrelationId,
          getMfeRoutes,
          getMfeStatus,
        }
      );
    } catch (error) {
      logApplicationError({
        type: getAppStatus(currentMfe.name),
        name: currentMfe.name,
        object: error,
        description: `RegisterApplication function Error`,
        code: "MFE-ERR06",
      });
    }
  });

  window.addEventListener("single-spa:routing-event", (event) => {
    // check if the mfe needs to be remounted
    if (
      event.detail.originalEvent &&
      event.detail.originalEvent.state.mfeContext
    ) {
      const {
        productAdminCd,
        policyNumber,
      } = event.detail.originalEvent.state.mfeContext;
      const { oldUrl, newUrl } = event.detail;
      if (policyNumber && productAdminCd) {
        if (newUrl === oldUrl) {
          const reloadUrl = new URL(newUrl);
          activeMFEs.forEach((currentMfe) => {
            currentMfe.mountingRoutes.forEach((route) => {
              if (route.startsWith(reloadUrl.pathname)) {
                unloadApplication(currentMfe.name);
              }
            });
          });
        }
      }
    }

    if (event.detail.originalEvent) {
      // for navigation within the same MFE
      const mfeContext =
        event.detail.originalEvent.state?.state?.mfeContext ||
        event.detail.originalEvent.state?.mfeContext;

      if (mfeContext) {
        const {
          productAdminCd = "",
          policyNumber = "",
          context = "",
        } = mfeContext;

        const { oldUrl, newUrl } = event.detail;
        const oldRoute = new URL(oldUrl).pathname;
        const newRoute = new URL(newUrl).pathname;

        const isEndorsementRouteChange = (route1, route2, routePairs) =>
          routePairs.some(
            ([r1, r2]) =>
              (route1 === r1 && route2 === r2) ||
              (route1 === r2 && route2 === r1)
          );

        const cemeRoutePairs = [
          ["/secured/endorsements", "/secured/endorsements-ceme"],
        ];

        if ((policyNumber && productAdminCd) || context) {
          if (
            (newUrl === oldUrl ||
              oldUrl.includes(newUrl) ||
              newUrl.includes(oldUrl)) &&
            !isEndorsementRouteChange(oldRoute, newRoute, cemeRoutePairs)
          ) {
            const reloadUrl = new URL(newUrl);
            activeMFEs.forEach((currentMfe) => {
              currentMfe.mountingRoutes.forEach((route) => {
                if (
                  currentMfe.name !== "@allstate/header" &&
                  currentMfe.name !== "@allstate/footer" &&
                  route.includes(reloadUrl.pathname)
                ) {
                  unloadApplication(currentMfe.name);
                }
              });
            });
          }
        }
      }
    }
    activeMFEs.forEach((currentMfe) => {
      if (
        window.location.href.includes(currentMfe.preloadOn) &&
        currentMfe.preloadOn !== ""
      ) {
        System.import(currentMfe.loader)
          .then((module) => module.load())
          .catch((err) => {
            logApplicationError({
              type: getAppStatus(currentMfe.name),
              name: currentMfe.name,
              object: err,
              description: `Error in preloading of MFE assets`,
              code: "MFE-ERR03",
            });
          });
      }
    });
  });
  window.addEventListener("single-spa:before-app-change", () => {
    const element = document.getElementById("wallaby-startupSpinner");
    if (element) {
      element.style.display = "block";
    }
    if (document.getElementById("da-error-block"))
      document.getElementById("da-error-block").remove();
  });
  addErrorHandler(mfeRetry);
  window.addEventListener("single-spa:app-change", (event) => {
    const element = document.getElementById("wallaby-startupSpinner");
    if (element) {
      element.style.display = "none";
    }
    const chatElement = document.getElementById("va_chat");
    if (chatElement) {
      chatElement.style.transform = "rotate(-90deg)";
      chatElement.style.position = "fixed";
      chatElement.style.top = "25rem";
      chatElement.style.right = 0;
    }
    event.detail.appsByNewStatus.MOUNTED.forEach((mountedApp) => {
      logApplicationEvent({
        type: "MOUNTED",
        name: mountedApp,
        description: `Application '${mountedApp}' was successfully Mounted`,
        code: "MFE-EVT01",
      });
    });
  });

  start();
}
