/* eslint-disable no-undef */
import {
  LoggerFactory,
  LoggingEventType,
  LoggingResultType,
} from "javascript-appfabric-logger";
import getCorrelationId from "../single-spa/get-correlation-id";
import getContext from "../communication/get-context";

const logConfig = {
  appName: PACKAGENAME,
  appVersion: PACKAGEVERSION,
  logServiceUrl: "/mfe/anon/ceme/wallaby/logger/logClientEvent",
  cacheEnabled: false
};

LoggerFactory.initialize(logConfig);

const createLogDetails = (logData, resultType) => {
  return {
    log_timestamp: new Date(Date.now()).toString(),
    application_id: `${logConfig.appName}-${logConfig.appVersion}`,
    application_address: window.location.href,
    service: "",
    code_location: "",
    barometer_id: "041800001NW4",
    source_address: "",
    interaction_id: "",
    severity: "",
    action: "",
    details: "",
    CorrelationID: getCorrelationId(),
    event_type: logData.type,
    event_name: logData.name,
    description: logData.description,
    code: logData.code,
    policyId: getContext()?.policyNumber || "",
    result: resultType,
    Channel: getContext()?.channel || ""
  };
};

const logApplicationEvent = (logData) => {
  const logger = LoggerFactory.getLogger();
  const logResultType = "Success";
  logger.info({
    eventType: LoggingEventType.APPLICATION,
    eventName: logData.name,
    object: JSON.stringify(createLogDetails(logData, logResultType)),
    description:  "Event Code: '" + logData.code + "' Type: '" + logData.type + "' Description: '" + logData.description + "'",
    businessContext: "",
    loggingResultType: LoggingResultType.SUCCESS,
  });
};

const logApplicationError = (logData) => {
  const logger = LoggerFactory.getLogger();
  const logResultType = "Fail";
  logger.error({
    eventType: LoggingEventType.APPLICATION,
    eventName: logData.name,
    object: JSON.stringify(createLogDetails(logData, logResultType)),
    description: "Error Code: '" + logData.code + "' Type: '" + logData.type + "' Description: '" + logData.description + "' LogData Obj: '" + logData.object + "'",
    businessContext: "",
    loggingResultType: LoggingResultType.FAILURE,
    reason: "",
    error: JSON.stringify(logData.object)
  });
};

export { logApplicationEvent, logApplicationError };
